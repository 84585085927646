import { css } from '@emotion/react';

import { CITY_CARD_SIZE } from '@/config/constants';
import { RouteConfig } from '@/config/routes';
import { boxStyle, ColorPalette, fontSize, fontWeight, media, spacing } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import { City } from '@/services/city';

import Link from '../Link/Link';

const styles = {
  imgContainer: (backgroundImage: string, isComingSoon: boolean) => css`
    cursor: ${isComingSoon ? 'auto' : 'pointer'};
    height: ${CITY_CARD_SIZE}px;
    width: ${CITY_CARD_SIZE}px;
    transition: 0.3s;
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    display: grid;
    row-gap: ${spacing['2']};
    padding: ${spacing['24']};
    align-content: flex-end;
    text-transform: uppercase;
    background-color: ${isComingSoon ? ColorPalette.black_60 : ColorPalette.black_30};
    background-blend-mode: multiply;
    border-radius: ${boxStyle.borderRadius};

    ${!isComingSoon &&
    css`
      &:hover {
        transition: 0.3s;
        transform: scale(1.02);
      }
    `}

    ${media.tablet} {
      &:hover {
        transform: none;
      }
    }
  `,
  cardContainer: (isComingSoon: boolean) => css`
    text-align: center;
    text-transform: uppercase;
    pointer-events: ${isComingSoon ? 'none' : 'auto'};
  `,
  title: css`
    font-size: ${fontSize[20]};
    color: ${ColorPalette.white};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  comingSoonLabel: css`
    font-size: ${fontSize[12]};
    font-weight: ${fontWeight.light};
    color: ${ColorPalette.white};
  `,
};

type CityProps = {
  city: City;
};

const CityCard = ({ city }: CityProps) => {
  const { t } = useTranslation();

  return (
    <Link
      href={RouteConfig.CityTours.buildLink({ city_slug: city.slug })}
      css={styles.cardContainer(city.is_coming_soon)}
    >
      <div css={styles.imgContainer(city.image.full, city.is_coming_soon)}>
        <div css={styles.title}>{city.title}</div>
        {city.is_coming_soon && <div css={styles.comingSoonLabel}>{t('msg_city_card_coming_soon')}</div>}
      </div>
    </Link>
  );
};

export default CityCard;
